<template>
  <v-container fluid>
    <v-row class="pa-5 pt-0">
      <v-col cols="12">
        <v-btn text class="rounded-xl text-capitalize" to="/panel-inicio">
          <v-icon>mdi-chevron-left</v-icon>
          <span>Volver al panel de inicio</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="rounded-xl pa-0" outlined>
      <v-row class="d-flex align-center justify-center pa-7">
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
          class="d-flex justify-center"
        >
          <credit-bar />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
          class="d-flex justify-center"
        >
          <v-btn
            class="white--text font-weight-bold text-capitalize rounded-pill"
            :color="color.primaryVariant"
            @click="balancePlansOpen = true"
          >
            Comprar más créditos
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs :color="color.primaryVariant" v-model="tab">
        <v-tab v-for="(item, i) in tabItems" :key="i">
          <span class="wc-caption-2 font-weight-bold">{{ item }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <organization-data />
        </v-tab-item>
        <v-tab-item>
          <digitalCertificate />
        </v-tab-item>
        <v-tab-item>
          <billing-data />
        </v-tab-item>
        <v-tab-item>
          <DebtsData/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <BalancePlans
      :open="balancePlansOpen"
      @open="balancePlansOpen = true"
      @close="balancePlansOpen = false"
    />
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";
import CreditBar from "../../../components/dashboard/creditBar.vue";
import BalancePlans from "../../../components/dashboard/DashboardNavbar/OrganizerBalance/BalancePlans.vue";
import organizationData from "./organizationData.vue";
import digitalCertificate from "./digitalCertificate.vue";
import billingData from "./billingData.vue";
import DebtsData from "./DebtsData.vue";

export default {
  components: {
    organizationData,
    digitalCertificate,
    billingData,
    BalancePlans,
    CreditBar,
    DebtsData
  },
  data() {
    return {
      color: colors,
      balancePlansOpen: false,
      tab: null,
      tabItems: [
        "Datos de la organización",
        "Certificado de sellos digitales",
        "Datos para facturación",
        "Datos fiscales de adeudos",
      ],
    };
  },
  mounted() {
    this.tab = parseInt(this.$route.query.tab) || 0;
  },
};
</script>
