<template>
  <v-container class="d-flex align-center justify-center pa-0">
    <v-card
          @click="handleImageClick"
          @dragover.prevent
          @drop.prevent="dropActive" 
          elevation="0"
          class="inputCard d-flex flex-column align-center justify-center rounded-xl"
          :max-width="$vuetify.breakpoint.smAndDown?210:300"
          width="300"                
          >
          <v-img class="rounded-0" contain height="150" :src="setCurrentUrl"/>
          <v-sheet class="d-flex align-center pt-3 justify-center rounded-b-xl"  width="100%"  :color="color.gray">
            <p class="pb-0" style="line-height: 13px; text-align: center; font-size: 11px;">                  
              <span class="font-weight-bold"
                >Arraste o haga click para cargar</span
              ><br />
              Se aceptan archivos jpg, png, jpeg de hasta 25Mb<br />
              Se recomienda tamaño de 300 x 300 px
            </p>
            <input
              hidden
              ref="editImage"
              :accept="['.jpg', '.jpeg', '.png']"
              type="file"
              @change="toggleActive"
            />
          </v-sheet>
        </v-card>
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";

export default{
  props:{
    currentUrl:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tempUrl:'',
      fileLoaded:null,
      color: colors,
    }
  },
  methods:{
    handleImageClick() {
      this.$refs.editImage.click();
    },
    toggleActive(e){
      this.fileLoaded = e.target.files[0];
      if (this.fileLoaded) {
        this.callData()
      }
    },
    dropActive(e){
      this.fileLoaded = e.dataTransfer.files[0]
      if (this.fileLoaded) {
        this.callData()
      }
    },
    callData(){
      this.tempUrl = URL.createObjectURL(this.fileLoaded)
      this.$emit('update', this.fileLoaded)
    }
  },
  computed:{
    setCurrentUrl(){
      return this.tempUrl || this.currentUrl
    }
  }
}
</script>
<style scoped>
  .inputCard {
  border: 2px solid #b2e5fd;
  border-style: dashed;
}
</style>