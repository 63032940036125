<template>
  <v-container fluid>
    <v-row class="ma-0">
      <span class="wc-overline text-primary-variant my-1">
        Tu plan actual tiene
        {{
          formatNumber(indicators.assignedItems+indicators.orderPaymentPendingItems+indicators.unassignedItems)
        }}
        créditos:
      </span>
    </v-row>
    <v-row class="ma-0">
      <div class="wrapper">
        <div class="assigned" :style="{ width: `${assignedValue}%` }"></div>
        <div
          class="pending"
          :style="{ width: `${pendingValue}%`, left: `${assignedValue}%` ,
          borderRadius: indicators.unassignedItems == 0 ?'0 50px 50px 0': '0'
          }"
        ></div>
      </div>
    </v-row>
    <v-row class="ma-0" align="center">
      <div
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column' : 'd-flex'"
      >
        <div>
          <v-icon :color="color.primaryVariant" size="16"> mdi-circle </v-icon>
          <span class="wc-overline ml-1">{{
            formatNumber(indicators.assignedItems)
          }}</span>
          <span class="wc-caption-2 mr-4">&nbsp; Asignados</span>
        </div>
        <div>
          <v-icon color="#ffb500" size="16"> mdi-circle </v-icon>
          <span class="wc-overline ml-1">{{
            formatNumber(indicators.orderPaymentPendingItems)
          }}</span>
          <span class="wc-caption-2 mr-4">&nbsp; Pendientes</span>
        </div>
        <div>
          <v-icon color="light-blue lighten-4" size="16"> mdi-circle </v-icon>
          <span class="wc-overline ml-1">{{
            formatNumber(indicators.unassignedItems)
          }}</span>
          <span class="wc-caption-2">&nbsp; Disponibles</span>
        </div>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { colors } from "@/constants/colors.js";

export default {
  data() {
    return {
      color: colors,
    };
  },
  computed: {
    ...mapState("user", ["indicators"]),
    assignedValue() {
      return (
        (this.indicators.assignedItems /
          (this.indicators.unassignedItems +
            this.indicators.assignedItems +
            this.indicators.orderPaymentPendingItems)) *
        100
      );
    },
    pendingValue() {
      return (
        (this.indicators.orderPaymentPendingItems /
          (this.indicators.unassignedItems +
            this.indicators.assignedItems +
            this.indicators.orderPaymentPendingItems)) *
        100
      );
    },
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 25px;
  background-color: #b3e5fc;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
}
.assigned {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1b273e;
  border-radius: 10px;
  border-end-end-radius: 0;
  border-start-end-radius: 0;
  z-index: 1;
}
.pending {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #ffb500;
  z-index: 2;
}
</style>
