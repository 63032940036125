<template>
  <v-container fluid :class="$vuetify.breakpoint.smAndDown ? 'py-9' : 'pa-9'">
    <!-- save buttom -->
    <div
      class="d-flex align-center"
      :class="user.debtsFiscalData ? 'justify-end' : 'justify-space-between'"
    >
      <span v-if="!user.debtsFiscalData" class="updateLabel">
        <v-icon color="orange" class="pr-2"> mdi-alert-circle </v-icon>
        Debes actualizar los datos fiscales para facturación de adeudos.
      </span>
      <!-- :disabled="unlock" -->
      <v-btn
        :color="color.secondary"
        :ripple="false"
        :fab="$vuetify.breakpoint.smAndDown"
        @click="_activeDialog"
        :small="$vuetify.breakpoint.smAndDown"
        :fixed="$vuetify.breakpoint.smAndDown"
        class="rounded-pill font-weight-bold mb-6 text-capitalize"
        :disabled="!this.user.debtsFiscalData ? false : unlock"
        :class="$vuetify.breakpoint.smAndDown ? 'saveButtom' : ''"
        :loading="buttonLoader"
      >
        <span v-if="!$vuetify.breakpoint.smAndDown">Guardar cambios</span>

        <v-icon v-else> mdi-content-save-outline </v-icon>
      </v-btn>
    </div>
    <PersonalizedDialog
      :dialog="dialog"
      @save="saveDebtsData()"
      @discard="dialog = false"
    >
      <template v-slot:alertName> Guardar cambios </template>
      <template v-slot:alertContent>
        <p class="title text-center">¿Está seguro que desea guardar cambios?</p>
      </template>
      <template v-slot:button> Guardar </template>
    </PersonalizedDialog>
    <!-- end save buttom -->

    <v-row>
      <!-- fiscal address -->
      <v-col cols="12">
        <v-card outlined class="pa-6 py-10 pb-3" style="position: relative">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Datos fiscales
          </span>
          <v-row>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
              class="py-0 ma-0"
            >
              <v-text-field
                :background-color="color.background"
                label="Clave de Unidad"
                rounded
                dense
                class="pb-2"
                height="40"
                v-model="fiscalData.unitKey"
                @input="fiscalData.unitKey = fiscalData.unitKey.toUpperCase()"
                @blur="fetchUnitDescription()"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
              class="py-0 ma-0"
            >
              <v-text-field
                label="No. de identificación"
                :background-color="color.background"
                dense
                class="pb-2"
                height="40"
                rounded
                v-model="fiscalData.number_of_id"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
              class="py-0 ma-0"
            >
              <v-text-field
                label="Nombre de Unidad"
                :background-color="color.background"
                dense
                readonly
                class="pb-2"
                height="40"
                rounded
                v-model="fiscalData.unitName"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
              class="py-0 ma-0"
            >
              <v-text-field
                label="Clave de Producto"
                :background-color="color.background"
                dense
                class="pb-2"
                height="40"
                rounded
                v-model="fiscalData.productKey"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 12"
              class="py-0 ma-0"
            >
              <v-textarea
                v-model="fiscalData.description"
                label="Descripción o Concepto"
                :background-color="color.background"
                dense
                class="pb-2"
                rounded
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- end fiscal address -->
    </v-row>
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";
import { mapMutations, mapState, mapActions } from "vuex";
import PersonalizedDialog from "../../../components/shared/PersonalizedDialog";

export default {
  components: {
    PersonalizedDialog,
  },
  data() {
    return {
      color: colors,
      dialog: false,
      buttonLoader: false,
      fiscalData: {
        unitKey: "",
        number_of_id: "",
        unitName: "",
        productKey: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    _activeDialog() {
      this.dialog = true;
    },
    async saveDebtsData() {
      try {
        this.buttonLoader = true;
        const response = await this.axios({
          method: "POST",
          url: "users/debts-fiscal-data",
          data: {
            unitKey: this.fiscalData.unitKey.toUpperCase(),
            number_of_id: this.fiscalData.number_of_id,
            unitName: this.fiscalData.unitName,
            productKey: this.fiscalData.productKey,
            description: this.fiscalData.description,
          },
        });

        if (response.status == 200) {
          this.show({
            text: "Datos fiscales actualizados correctamente",
            color: "success",
            time: 3000,
          });
        }
      } catch (error) {
        this.show({
          text: error.response.data.error.message,
          color: "error",
          time: 3000,
        });
      } finally {
        this.buttonLoader = false;
      }
    },
    async fetchUnitDescription() {
      try {
        const response = await this.axios.post("invoices/validate-unit-name", {
          unitKey: this.fiscalData.unitKey.toUpperCase(),
        });
        const unitNames = response.data.unitNames;

        if (unitNames.length > 0) {
          this.fiscalData.unitName = unitNames[0].description;
        } else {
          this.fiscalData.unitName = "";
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created () {
    if (this.user.debtsFiscalData) {
      this.fiscalData.unitKey = this.user.debtsFiscalData.unitKey;
      this.fiscalData.number_of_id = this.user.debtsFiscalData.number_of_id;
      this.fiscalData.unitName = this.user.debtsFiscalData.unitName;
      this.fiscalData.productKey = this.user.debtsFiscalData.productKey;
      this.fiscalData.description = this.user.debtsFiscalData.description;
    }
  },
};
</script>
<style scoped>
.labelCard {
  position: absolute;
  top: -13px;
  left: 1;
  z-index: 2;
  background-color: white;
  font-weight: 700;
}
.saveButtom {
  z-index: 3;
  right: 20px;
  bottom: -5px;
}
.updateLabel {
  font-size: 18px;
  font-weight: 800;
}
</style>