<template>
  <v-container fluid :class="$vuetify.breakpoint.smAndDown ? 'py-9' : 'pa-9'">
    <!-- save buttom -->
    <div
      class="d-flex align-center"
      :class="user.billingData ? 'justify-end' : 'justify-space-between'"
    >
      <span v-if="!user.billingData" class="updateLabel">
        <v-icon color="orange" class="pr-2"> mdi-alert-circle </v-icon>
        Debes actualizar los campos de identificación fiscal.
      </span>
      <!-- :disabled="unlock" -->
      <v-btn
        :color="color.secondary"
        :ripple="false"
        :fab="$vuetify.breakpoint.smAndDown"
        @click="_activeDialog"
        :small="$vuetify.breakpoint.smAndDown"
        :fixed="$vuetify.breakpoint.smAndDown"
        class="rounded-pill font-weight-bold mb-6 text-capitalize"
        :disabled="!this.user.billingData ? false : unlock"
        :class="$vuetify.breakpoint.smAndDown ? 'saveButtom' : ''"
        :loading="buttonLoader"
      >
        <span v-if="!$vuetify.breakpoint.smAndDown && user.billingData"
          >Guardar cambios</span
        >
        <span v-else-if="!$vuetify.breakpoint.smAndDown && !user.billingData"
          >Validar Datos</span
        >
        <v-icon v-else> mdi-content-save-outline </v-icon>
      </v-btn>
    </div>
    <PersonalizedDialog
      :dialog="dialog"
      @save="updateAllData"
      @discard="dialog = false"
    >
      <template v-slot:alertName>
        {{ user.billingData ? "Guardar cambios" : "Validar cambios" }}
      </template>
      <template v-slot:alertContent>
        <p class="title text-center">
          {{
            user.billingData
              ? "¿Está seguro que desea guardar cambios?"
              : "Asegurate de que todos tus cambios estén correctamente actualizados"
          }}
        </p>
      </template>
      <template v-slot:button>
        {{ user.billingData ? "Guardar" : "Validar" }}
      </template>
    </PersonalizedDialog>
    <!-- end save buttom -->

    <v-row>
      <!-- fiscal identification data -->
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <v-card outlined class="pa-6 py-10 pb-3" style="position: relative">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Datos de identificación fiscal
          </span>
          <v-row class="py-8">
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
              class="py-0 ma-0 pb-2"
              v-for="(item, i) in billingData"
              :key="i"
            >
              <v-text-field
                v-if="item.id != 'fiscalRegimen'"
                :background-color="color.background"
                rounded
                dense
                :rules="item.rule"
                :hint="
                  item.id == 'rfc'
                    ? '  *Se llena al cargar tus sellos digitales'
                    : ''
                "
                persistent-hint
                :readonly="item.id == 'rfc'"
                class="pb-2 d-flex align-center"
                height="40"
                :label="item.label"
                v-model="item.value"
              >
                <v-tooltip
                  top
                  max-width="300"
                  slot="append"
                  v-if="item.id === 'legalName'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="pt-2" v-bind="attrs" v-on="on">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span
                    >Debe escribirse en mayúsculas, sin acentos, y en caso de
                    persona moral, sin régimen societario (por ejemplo sin S.A.
                    de C.V.).</span
                  >
                </v-tooltip>
              </v-text-field>
              <v-select
                v-else
                :background-color="color.background"
                rounded
                :rules="item.rule"
                dense
                :items="fiscalRegimenItems"
                class="pb-2"
                height="40"
                :label="item.label"
                v-model="item.value"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- end fiscal identification data  -->

      <!-- custom invoice  -->
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-card outlined class="pa-6 py-10" style="position: relative">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Personalización de factura
          </span>

          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
              <dropImageUploaderVue
                :currentUrl="logoUrl"
                @update="handleUpdateLogo"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
              class="pa-2 pl-0"
            >
              <v-sheet
                :color="color.background"
                class="d-flex pa-3 flex-column rounded-lg align-center"
              >
                <span
                  :style="`color:${color.primaryVariant}; font-size:18px`"
                  class="font-weight-bold pb-2 text-center"
                  >Color de factura</span
                >

                <v-sheet
                  @click="isColorDialogOpen = true"
                  class="rounded-lg py-5 pointer"
                  width="70%"
                  height="50"
                  :color="customization.color || color.gray"
                />
              </v-sheet>
              <v-text-field
                :background-color="color.background"
                rounded
                dense
                :rules="invoiceNumberRules"
                class="ma-0 pa-0 mt-6"
                height="40"
                label="N° de folio"
                v-model="customization.nextFolioNumber"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- end custom invoice -->

      <!-- fiscal address -->
      <v-col cols="12">
        <v-card outlined class="pa-6 py-10 pb-3" style="position: relative">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Domicilio Fiscal
          </span>
          <v-row>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
              class="py-0 ma-0"
              v-for="(item, i) in fiscalAddress"
              :key="i"
            >
              <v-text-field
                :background-color="color.background"
                rounded
                dense
                :rules="item.rule"
                class="pb-2"
                height="40"
                :label="item.label"
                v-model="item.value"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- end fiscal address -->
    </v-row>

    <!-- color dialog picker -->
    <v-dialog v-model="isColorDialogOpen" max-width="400">
      <v-card>
        <p class="headline text-center font-weight-medium px-7 pt-5">
          Selecciona el color de su factura
        </p>
        <v-card-text class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="customization.color"
            class="ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            color="grey lighten-2"
            class="rounded-pill text-capitalize"
            @click="isColorDialogOpen = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="accent"
            class="rounded-pill text-capitalize"
            @click="isColorDialogOpen = false"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";
import dropImageUploaderVue from "../../../components/shared/dropImageUploader.vue";
import Endpoints from "@/share/Endpoints";
import { fiscalRegimenItems } from "@/utils/fiscalRegimen";
import { validations } from "@/share/validations";
import { mapMutations, mapState, mapActions } from "vuex";
import PersonalizedDialog from "../../../components/shared/PersonalizedDialog";

export default {
  components: {
    dropImageUploaderVue,
    PersonalizedDialog,
  },
  async mounted() {
    this.loading();
    try {
      await this.getFiscalData();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
  data() {
    return {
      color: colors,
      fiscalRegimenItems,
      isColorDialogOpen: false,
      unlock: true,
      buttonLoader: false,
      logoUrl: "",
      dialog: false,
      temporalBillingData: [],
      temporalFiscalAddress: [],
      temporalCustomization: "",
      updateFunctions: {
        updateBilling: false,
        updateAddress: false,
        updateCustom: false,
      },
      customization: {},
      specialFields: {
        rfc: "taxId",
        fiscalRegimen: "taxSystem",
        email: "contactEmail",
      },
      invoiceNumberRules: validations.generalMValidation({
        required: true,
        name: "Número de folio",
      }),
      billingData: [
        {
          value: "",
          label: "Nombre comercial",
          id: "name",
          rule: validations.generalMValidation({
            required: true,
            name: "Nombre comercial",
          }),
        },
        {
          value: "",
          label: "Razón social",
          id: "legalName",
          rule: validations.generalFValidation({
            required: true,
            name: "Razón social",
          }),
        },
        {
          value: "",
          label: "RFC o ID Fiscal",
          id: "rfc", //taxId
        },
        {
          value: "",
          label: "Regimen Fiscal",
          id: "fiscalRegimen", //taxSystem
          rule: validations.generalMValidation({
            required: true,
            name: "Regimen Fiscal",
          }),
        },
        {
          value: "",
          label: "Sitio Web",
          id: "website",
          rule: validations.websiteValidation({
            required: true,
            name: "Sitio web",
          }),
        },
        {
          value: "",
          label: "Correo electrónico",
          id: "email", //contactEmail
          rule: validations.emailValidation(),
        },
        {
          value: "",
          label: "Teléfono",
          id: "phone",
          rule: validations.phoneValidation({
            required: true,
            name: "Teléfono",
          }),
        },
      ],
      fiscalAddress: [
        {
          value: "",
          label: "Calle",
          id: "street",
          rule: validations.generalFValidation({
            required: true,
            name: "Calle",
          }),
        },
        {
          value: "",
          label: "N° Exterior",
          id: "exterior",
          rule: validations.generalMValidation({
            required: true,
            name: "Número exterior",
          }),
        },
        {
          value: "",
          label: "N° Interior",
          id: "interior",
          rule: validations.generalMValidation({
            required: true,
            name: "Número interior",
          }),
        },
        {
          value: "",
          label: "Cód. Postal",
          id: "zip",
          rule: validations.zipCodeValidation({
            required: true,
            name: "Código postal",
            minLength: 5,
            maxLength: 5,
          }),
        },
        {
          value: "",
          label: "Colonia",
          id: "neighborhood",
          rule: validations.generalFValidation({
            required: true,
            name: "Colonia",
          }),
        },
        {
          value: "",
          label: "Ciudad",
          id: "city",
          rule: validations.generalFValidation({
            required: true,
            name: "Ciudad",
          }),
        },
        {
          value: "",
          label: "Municipio",
          id: "municipality",
          rule: validations.generalMValidation({
            required: true,
            name: "Municipio",
          }),
        },
        {
          value: "",
          label: "Estado",
          id: "state",
          rule: validations.generalMValidation({
            required: true,
            name: "Estado",
          }),
        },
      ],
    };
  },
  watch: {
    billingData: {
      deep: true,
      handler(ndata) {
        let newData = ndata.map((e) => e.value || "");
        if (
          JSON.stringify(newData) == JSON.stringify(this.temporalBillingData)
        ) {
          this.unlock = true;
          this.updateFunctions.updateBilling = false;
        } else {
          this.unlock = false;
          this.updateFunctions.updateBilling = true;
        }
      },
    },
    fiscalAddress: {
      deep: true,
      handler(ndata) {
        let newData = ndata.map((e) => e.value || "");
        if (
          JSON.stringify(newData) == JSON.stringify(this.temporalFiscalAddress)
        ) {
          this.unlock = true;
          this.updateFunctions.updateAddress = false;
        } else {
          this.unlock = false;
          this.updateFunctions.updateAddress = true;
        }
      },
    },
    customization: {
      deep: true,
      handler(ndata) {
        if (JSON.stringify(ndata) === this.temporalCustomization) {
          this.unlock = true;
          this.updateFunctions.updateCustom = false;
        } else {
          this.unlock = false;
          this.updateFunctions.updateCustom = true;
        }
      },
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["realoadUserData"]),
    _activeDialog() {
      this.dialog = true;
    },
    async getFiscalData() {
      const response = await this.axios.get(Endpoints.invoicesGetData);
      if (response.status == 200) {
        this.billingData.forEach((e) => {
          if (Object.keys(this.specialFields).includes(e.id)) {
            e.id == "fiscalRegimen"
              ? (e.value = parseInt(response.data[this.specialFields[e.id]]))
              : (e.value = response.data[this.specialFields[e.id]]);
          } else {
            e.value = response.data[e.id];
          }
        });

        this.fiscalAddress.forEach((e) => {
          e.value = response.data.address[e.id];
        });

        this.logoUrl = response.data.logoUrl;
        this.customization = {
          color: response.data.customization.color,
          nextFolioNumber:
            response.data.customization.nextFolioNumber.toString(),
        };

        this.temporalBillingData = this.billingData.map((e) => e.value || "");
        this.temporalFiscalAddress = this.fiscalAddress.map(
          (e) => e.value || ""
        );
        this.temporalCustomization = JSON.stringify(this.customization);
      }
    },

    async updateAllData() {
      this.buttonLoader = true;
      if (
        this.updateFunctions.updateBilling == true ||
        this.updateFunctions.updateAddress == true ||
        !this.user.billingData
      ) {
        await this.updateTaxData();
      }
      if (this.updateFunctions.updateCustom == true) {
        await this.updateCustomization();
      }
      this.dialog = false;
      this.buttonLoader = false;
    },

    async updateTaxData() {
      let payload = {
        invoiceServiceInformation: { address: {} },
      };
      try {
        this.billingData.forEach((e) => {
          e.id == "fiscalRegimen"
            ? (payload.invoiceServiceInformation.taxSystem = e.value.toString())
            : (payload.invoiceServiceInformation[e.id] = e.value);
        });
        this.fiscalAddress.forEach((e) => {
          payload.invoiceServiceInformation.address[e.id] = e.value;
        });
        const response = await this.axios.put(
          Endpoints.invoicesUpdateTaxData,
          payload
        );
        if (response.status === 200) {
          this.show({
            text: "Datos fiscales actualizados correctamente",
            color: "success",
            time: 3000,
          });
          this.temporalBillingData = this.billingData.map((e) => e.value || "");
          this.temporalFiscalAddress = this.fiscalAddress.map(
            (e) => e.value || ""
          );
          this.unlock = true;
          if (!this.user.billingData) {
            this.user.billingData = true;
          }
        }
      } catch (error) {
        this.show({
          text: error.response.data.error.message,
          color: "error",
          time: 3000,
        });
      }
    },

    async handleUpdateLogo(file) {
      const formData = new FormData();
      formData.append("logo", file);
      this.show({
        text: "Cargando...",
        color: "orange",
      });
      const { status, data } = await this.axios.put(
        Endpoints.invoicesUpdateLogo,
        formData
      );
      if (status === 200) {
        this.logoUrl = data.logoUrl;
        this.show({
          text: "Logo actualizado correctamente",
          color: "success",
        });
      }
    },

    async updateCustomization() {
      const response = await this.axios.put(
        Endpoints.invoicesUpdateCustomization,
        this.customization
      );
      if (response.status === 200) {
        this.show({
          text: "Los campos de personalización de la factura se actualizaron correctamente",
          color: "success",
        });
      }
      this.temporalCustomization = JSON.stringify(this.customization);
      this.unlock = true;
    },
  },
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>
<style scoped>
.labelCard {
  position: absolute;
  top: -13px;
  left: 1;
  z-index: 2;
  background-color: white;
  font-weight: 700;
}
.saveButtom {
  z-index: 3;
  right: 20px;
  bottom: -5px;
}
.updateLabel {
  font-size: 18px;
  font-weight: 800;
}
</style>