<template>
  <v-container :class="$vuetify.breakpoint.smAndDown ? 'py-9' : 'pa-9'">
    <div class="d-flex justify-end mb-5">
      <v-btn
        :color="color.secondary"
        :ripple="false"
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        :loading="buttonLoader"
        :fixed="$vuetify.breakpoint.smAndDown"
        @click="handleEdit"
        class="rounded-pill font-weight-bold text-capitalize"
        :class="$vuetify.breakpoint.smAndDown ? 'saveButtom' : ''"
      >
        <span v-if="!$vuetify.breakpoint.smAndDown">{{
          editInfo ? "Cancelar" : "Editar Información"
        }}</span>
        <v-icon v-else>
          {{ editInfo ? "close-octagon-outline" : "mdi-pencil-outline" }}
        </v-icon>
      </v-btn>

      <v-btn
        v-if="editInfo"
        :color="color.primaryVariant"
        :ripple="false"
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        :loading="buttonLoader"
        :fixed="$vuetify.breakpoint.smAndDown"
        @click="updateSCDData"
        class="rounded-pill font-weight-bold text-capitalize ml-3 white--text"
        :class="$vuetify.breakpoint.smAndDown ? 'saveButtom' : ''"
      >
        <span v-if="!$vuetify.breakpoint.smAndDown" class="white--text"
          >Guardar cambios</span
        >
        <v-icon v-else> mdi-content-save-outline </v-icon>
      </v-btn>
    </div>
    <div>
      <p style="line-height: 32px; font-size: 13px">
        <span
          class="font-weight-bold"
          :style="{ color: color.primaryVariant, fontSize: '18px' }"
          >Certificados de sellos digitales (CSD)</span
        ><br />
        <span
          >Carga aquí Los sellos con los cuales se emitiran facturas automáticas
          al momento en que alguien compre un evento o membresía de tu
          organización.</span
        >
      </p>
      <span
        :style="{ color: color.primaryVariant }"
        class="font-weight-bold mb-6"
      >
        <v-icon :color="color.primaryVariant">mdi-help-circle-outline</v-icon>
        Ver tutorial
      </span>
    </div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center mt-5">
        <v-card outlined class="pa-6 py-10 pb-3" width="900">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <span
                class="font-weight-bolg"
                :style="{
                  color: color.primaryVariant,
                  fontWeight: 700,
                  fontSize: '16px',
                }"
                >Certificado (.cer)</span
              >
              <v-card
                elevation="0"
                @click="toggleCerFile"
                @dragover.prevent
                @drop.prevent="cerActive, !editInfo"
                class="inputCard rounded-md mt-5"
                :disabled="!editInfo"
              >
                <v-sheet height="87" class="d-flex justify-center align-center">
                  <v-sheet
                    v-if="
                      (user.invoiceSetup || cerLoaded != null) &&
                      (!editInfo || cerEdited)
                    "
                    height="50"
                    width="90%"
                    class="rounded-lg d-flex justify-center align-center"
                    color="#b3e5fc"
                  >
                    <v-icon class="pr-2"> mdi-script-text-outline </v-icon>
                    Certificado Cargado
                    <span> </span>
                  </v-sheet>
                  <p v-else class="text-center ma-0" style="font-size: 13px">
                    <span
                      :style="{ color: color.primaryVariant, fontWeight: 800 }"
                      >Arraste o haga click para cargar archivo</span
                    ><br />
                    <span>Se aceptan archivos .cer</span>
                  </p>
                </v-sheet>
                <input
                  hidden
                  ref="cerInput"
                  :accept="['.cer']"
                  type="file"
                  @change="toggleActiveCer"
                />
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <span
                class="font-weight-bolg"
                :style="{
                  color: color.primaryVariant,
                  fontWeight: 700,
                  fontSize: '16px',
                }"
                >Llave privada (.key)</span
              >
              <v-card
                elevation="0"
                @click="toggleKeyFile"
                @dragover.prevent
                @drop.prevent="keyActive, !editInfo"
                class="inputCard rounded-md mt-5"
                :disabled="!editInfo"
              >
                <v-sheet height="87" class="d-flex align-center justify-center">
                  <v-sheet
                    v-if="
                      (user.invoiceSetup || keyLoaded != null) &&
                      (!editInfo || keyEdited)
                    "
                    height="50"
                    width="90%"
                    class="rounded-lg d-flex justify-center align-center"
                    color="#b3e5fc"
                  >
                    <v-icon class="pr-2"> mdi-key-variant </v-icon>
                    Llave privada Cargada
                    <span> </span>
                  </v-sheet>
                  <p v-else class="text-center ma-0" style="font-size: 13px">
                    <span
                      :style="{ color: color.primaryVariant, fontWeight: 800 }"
                      >Arraste o haga click para cargar archivo</span
                    ><br />
                    <span>Se aceptan archivos .key</span>
                  </p>
                </v-sheet>
                <input
                  hidden
                  ref="keyInput"
                  :accept="['.key']"
                  type="file"
                  @change="toggleActiveKey"
                />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :background-color="color.background"
                rounded
                dense
                hint="*Asegúrate de estar cargando la contraseña correcta, no confundir con la FIEL"
                class="pb-2"
                height="40"
                label="Contraseña de llave"
                v-model="password"
                :disabled="!editInfo"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { colors } from "@/constants/colors.js";
import Endpoints from "@/share/Endpoints";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      color: colors,
      keyLoaded: null,
      cerLoaded: null,
      keyEdited: false,
      cerEdited: false,
      invoiceData: null,
      disableButton: false,
      password: "",
      buttonLoader: false,
      editInfo: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    disableLoadButton() {
      return !(
        this.keyLoaded != null &&
        this.cerLoaded != null &&
        this.password != ""
      );
    },
    disableFields() {
      console.log(this.invoiceData.expiresAt);
      const date = new Date(this.invoiceData.expiresAt);
      if (this.user.invoiceSetup && Date.parse(date) < Date.parse(new Date())) {
        return true;
      } else if (
        Date.parse(date) > Date.parse(new Date()) ||
        this.user.invoiceSetup == false
      ) {
        return false;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["login", "realoadUserData"]),
    toggleCerFile() {
      this.$refs.cerInput.click();
    },
    toggleKeyFile() {
      this.$refs.keyInput.click();
    },
    toggleActiveCer(e) {
      if (this.editInfo == true) {
        this.cerEdited = true;
      }
      this.cerLoaded = e.target.files[0];
      this.errorMessage(this.cerLoaded, ".cer");
    },
    toggleActiveKey(e) {
      if (this.editInfo == true) {
        this.keyEdited = true;
      }
      this.keyLoaded = e.target.files[0];
      this.errorMessage(this.keyLoaded, ".key");
    },
    cerActive(e) {
      this.cerLoaded = null;
      this.cerLoaded = e.dataTransfer.files[0];
      this.errorMessage(this.cerLoaded, ".cer");
    },
    keyActive(e) {
      this.keyLoaded = null;
      this.keyLoaded = e.dataTransfer.files[0];
      this.errorMessage(this.keyLoaded, ".key");
    },

    handleEdit() {
      console.log(this.editInfo);
      if (this.editInfo == true) {
        this.cerEdited = false;
        this.keyEdited = false;
        this.cerLoaded = null;
        this.keyLoaded = null;
      }
      this.editInfo = !this.editInfo;
    },

    errorMessage(doc, format) {
      console.log(doc);
      if (!String(doc.name).includes(".cer") && format === ".cer") {
        this.show({
          text: "Cargar solo documentos .cer",
          color: "error",
          time: 4000,
        });
        this.cerLoaded = null;
      }
      if (!String(doc.name).includes(".key") && format === ".key") {
        this.show({
          text: "Cargar solo documentos .key",
          color: "error",
          time: 4000,
        });
        this.keyLoaded = null;
      }
    },

    async updateSCDData() {
      this.buttonLoader = true;
      try {
        const formData = new FormData();
        formData.append("cer", this.cerLoaded);
        formData.append("key", this.keyLoaded);
        formData.append("password", this.password);
        const response = await this.axios.put(
          Endpoints.invoicesUpdateCSD,
          formData
        );
        if (response.status === 200) {
          await this.realoadUserData({ email: this.user.email });
          this.show({
            text: "CSD cargado correctamente",
            color: "success",
            time: 4000,
          });
        }
      } catch (e) {
        console.log(e.response.data.error.message);
        this.show({
          text: e.response.data.error.message,
          color: "error",
          time: 3000,
        });
        this.cerLoaded = null;
        this.keyLoaded = null;
        this.password = "";
      } finally {
        this.buttonLoader = false;
        this.editInfo = false;
        this.cerEdited = false;
        this.keyEdited = false;
      }
    },
  },
};
</script>

<style scoped>
.labelCard {
  position: absolute;
  top: -13px;
  left: 1;
  z-index: 2;
  background-color: white;
  font-weight: 700;
}

.inputCard {
  border: 2px solid #b2e5fd;
  border-style: dashed;
}
</style>